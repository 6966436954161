var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "exchange" }, [
    _c(
      "div",
      { staticClass: "nav" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "mini", type: "primary" },
            on: {
              click: function ($event) {
                return _vm.goAdd()
              },
            },
          },
          [_vm._v("新建活动")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tb-wrap" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tbLoad,
                expression: "tbLoad",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tbList,
              height: _vm.getViewHeight() - 200,
              border: "",
              "highlight-current-row": "",
              "header-cell-style": {
                background: "#D9DDE1",
                color: "#0D0202",
                "font-weight": 400,
                fontSize: "12px",
              },
              "row-key": "id",
            },
          },
          [
            _c("el-table-column", {
              attrs: { align: "center", prop: "id", label: "活动ID" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", prop: "date", label: "指定商品" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getGoodText(row.exchangeMainGoodsSkuDtoList)
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { align: "center", prop: "date", label: "换购商品" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getGoodText(
                                row.exchangeRelationGoodsSkuDtoList
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { align: "center", prop: "date", label: "状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.exchangeStatus == 0
                              ? "未开始"
                              : row.exchangeStatus == 1
                              ? "进行中"
                              : "已结束"
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "上下架", width: "120" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "div",
                        { staticClass: "flex flex-m" },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 0,
                              "inactive-value": 1,
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeStatus($event, row)
                              },
                            },
                            model: {
                              value: row.status,
                              callback: function ($$v) {
                                _vm.$set(row, "status", $$v)
                              },
                              expression: "row.status",
                            },
                          }),
                          _c("span", { staticClass: "tb-slot-status" }, [
                            _vm._v(_vm._s(row.status == 0 ? "上架" : "下架")),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "300",
                align: "center",
                prop: "date",
                label: "活动时间",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `${_vm.getFormatDate(
                                row.startTime
                              )} - ${_vm.getFormatDate(row.endTime)}`
                            ) +
                            " "
                        ),
                      ]),
                      row.validType == 1
                        ? _c("div", [
                            _vm._v(
                              " 每日" +
                                _vm._s(
                                  `${_vm.getFormatTime(
                                    row.perDayStartTime
                                  )} - ${_vm.getFormatTime(row.perDayEndTime)}`
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                width: "100",
                fixed: "right",
                prop: "address",
                label: "操作",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.goEdit("view", row.id)
                            },
                          },
                        },
                        [_vm._v("查看")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.goEdit("edit", row.id)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("Pagination", {
          attrs: {
            total: _vm.page.total,
            "page-size": _vm.page.pageSize,
            "current-page": _vm.page.page,
          },
          on: {
            "current-change": _vm.changePage,
            "size-change": _vm.changePageSize,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }