<template>
  <div class="exchange">
    <div class="nav">
      <el-button size="mini" @click="goAdd()" type="primary"
        >新建活动</el-button
      >
    </div>
    <div class="tb-wrap">
      <el-table
        v-loading="tbLoad"
        :data="tbList"
        :height="getViewHeight() - 200"
        border
        style="width: 100%"
        highlight-current-row
        :header-cell-style="{
          background: '#D9DDE1',
          color: '#0D0202',
          'font-weight': 400,
          fontSize: '12px',
        }"
        row-key="id"
      >
        <el-table-column align="center" prop="id" label="活动ID">
        </el-table-column>
        <el-table-column align="center" prop="date" label="指定商品">
          <template slot-scope="{ row }">
            <div>
              {{ getGoodText(row.exchangeMainGoodsSkuDtoList) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="date" label="换购商品">
          <template slot-scope="{ row }">
            <div>
              {{ getGoodText(row.exchangeRelationGoodsSkuDtoList) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="date" label="状态">
          <template slot-scope="{ row }">
            {{
              row.exchangeStatus == 0
                ? "未开始"
                : row.exchangeStatus == 1
                ? "进行中"
                : "已结束"
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="上下架" width="120">
          <template slot-scope="{ row }">
            <div class="flex flex-m">
              <el-switch
                v-model="row.status"
                :active-value="0"
                :inactive-value="1"
                active-color="#13ce66"
                @change="changeStatus($event, row)"
                inactive-color="#ff4949"
              >
              </el-switch>
              <span class="tb-slot-status">{{
                row.status == 0 ? "上架" : "下架"
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="300"
          align="center"
          prop="date"
          label="活动时间"
        >
          <template slot-scope="{ row }">
            <div>
              {{
                `${getFormatDate(row.startTime)} - ${getFormatDate(
                  row.endTime
                )}`
              }}
            </div>
            <div v-if="row.validType == 1">
              每日{{
                `${getFormatTime(row.perDayStartTime)} - ${getFormatTime(
                  row.perDayEndTime
                )}`
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="100"
          fixed="right"
          prop="address"
          label="操作"
        >
          <template slot-scope="{ row }">
            <el-button @click="goEdit('view', row.id)" type="text" size="small"
              >查看</el-button
            >
            <el-button @click="goEdit('edit', row.id)" type="text" size="small"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        @current-change="changePage"
        @size-change="changePageSize"
        :total="page.total"
        :page-size="page.pageSize"
        :current-page="page.page"
      />
    </div>
  </div>
</template>

<script>
import { exchangeOrderList, updateStatus } from "@/api/order";
import { dateFormat } from "@/utils/utils";
export default {
  data() {
    return {
      tbLoad: false,
      tbList: [],
      //分页数据
      page: {
        page: 0,
        pageSize: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.tbLoad = true;
      const pars = {
        currentPage: this.page.page,
        pageSize: this.page.pageSize,
      };

      exchangeOrderList(pars).then((res) => {
        this.tbLoad = false;
        if (res.code === 0) {
          this.tbList = res.data.records;
          this.page.total = +res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeStatus(val, row) {
      const pars = {
        id: row.id,
        status: val,
      };
      updateStatus(pars).then((res) => {
        if (res.code === 0) {
          this.$message.success(val == 0 ? "上架成功" : "下架成功");
        } else {
          if (val == 0) {
            row.status = 1;
          } else {
            row.status = 0;
          }
          this.$message.error(res.msg);
        }
      });
    },
    getFormatDate(time) {
      if (!time) return "";

      return dateFormat(time, "yyyy-MM-dd");
    },
    getFormatTime(time) {
      let zeroTime = new Date(new Date().setHours(0, 0, 0, 0)).valueOf();

      let timeAmount = new Date(+time + zeroTime);
      
      return dateFormat(timeAmount, 'hh:mm:ss');
    },
    getGoodText(arr) {
      let list = arr.map((e) => {
        return e.goodsName;
      });

      return list.join("，");
    },
    goAdd() {
      this.$router.push({
        path: "/nb/marketingTools/exchange/edit",
        query: { type: "add" },
      });
    },
    //去编辑页面
    goEdit(type, id) {
      this.$router.push({
        path: "/nb/marketingTools/exchange/edit",
        query: { type, id },
      });
    },
    changePage(p) {
      console.log(p);
      this.page.page = p;
      this.getList();
    },
    changePageSize(s) {
      console.log(s);
      this.page.pageSize = s;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.exchange {
  background: #fff;
  border-radius: 10px;
  padding: 0 15px;
  .nav {
    padding: 10px 0;
  }
  .tb-slot-status {
    font-size: 14px;
    color: #1f2d3d;
    margin-left: 5px;
  }
}
</style>
